// @TODO[FLOW-381]: fix unicorn/consistent-function-scoping

export const useTextUtils = () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const camelToSnake = (string: string): string => string.replaceAll(
        /[A-Z]/g,
        (letter): string => `_${letter.toLowerCase()}`,
    );
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const kebabToSnake = (string: string): string => string.replaceAll('-', '_');
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const snakeToCamel = (string: string): string => string.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const snakeToKebab = (string: string): string => string.replaceAll('_', '-');

    const snakeToPascal = (string: string): string => {
        const camelCase = snakeToCamel(string);
        const pascalCase = camelCase[0].toUpperCase() + camelCase.slice(1);

        return pascalCase;
    };
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const spaceToKebab = (string: string): string => string.replaceAll(' ', '-');

    return {
        camelToSnake,
        capitalizeFirstLetter,
        kebabToSnake,
        snakeToKebab,
        snakeToPascal,
        spaceToKebab,
    };
};
